import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import { Helmet } from "react-helmet";
import imageAero1 from "images/talAirplane1.jpg";
import imageFlotteTal from "images/flotte-tal1.jpg";

const BlogSingle = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="space-y-5 text-center">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xlcontent-center relative left-1/2 transform -translate-x-1/2"
            title="Quiet ingenuity: 120,000 lunches and counting" 
          >
            CONDITIONS DE TRANSPORT
          </h1>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
      id="single-entry-content"
      className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
    >

<h2>NOTICE</h2>

<p className="text-justify">If the passenger’s journey involves an ultimate destination or stop in a country other than the country of departure the Warsaw Convention or the Montreal Convention may be applicable and these Conventions govern and may limit the liability of carriers for death or bodily injury and in respect of loss of or damage to baggage. See also notices headed “Advice to International Passengers on Limitation of Liability” and ‘Notice of Baggage Liability Limitations”</p>

<h3>CONDITIONS OF CONTRACT</h3>
<ol>
<li className="text-justify"> As used in this contract: “ticket” means this passenger ticket and baggage check or this itinerary/receipt if applicable, in the case of an electronic ticket, of which these conditions and the notices form part; “carriage” is equivalent to “transportation”; “carrier” means all air carriers that carry or undertake to carry the passenger or his baggage hereunder or perform any other service incidental to such air carriage; “electronic ticket” means the Itinerary/Receipt issued by or on behalf of Carrier, the Electronic Coupons and, if applicable, a boarding document; “Warsaw Convention” means the Convention for the Unification of Certain Rules relating to International Carriage by Air signed at Warsaw, 12th October 1929, or that Convention as amended at The Hague, 28th September 1955, whichever may be applicable.</li>

<li className="text-justify"> Carriage hereunder is subject to the rules and limitations relating to liability established by either the Warsaw Convention or the Montreal Convention unless such carriage is not “International Carriage” as defined by that Convention.</li>

<li className="text-justify"> To the extent not in conflict with the foregoing carriage and other services performed by each carrier are subject to: (i) provisions contained in the ticket; (ii) applicable tariffs; (iii) carrier’s conditions of carriage and related regulation which are made part hereof (and are available on application at the offices of carrier), except in transportation between a place in the United States or Canada and any place outside thereof to which tariffs in force in those countries apply.</li>

<li className="text-justify"> Carrier’s name may be abbreviated in the ticket, the full name and its abbreviation being set forth in carrier’s tariffs, conditions of carriage, regulations or timetables; carrier’s address shall be at the airport of departure shown opposite the first abbreviation of carrier’s name in the ticket; the agreed stopping places are those places set forth in this ticket, or itinerary/receipt or any supplement to it, or as shown in carrier’s timetable as scheduled stopping places on the passenger’s route; carriage to be performed hereunder by several successive carriers is regarded as a single operation.</li>

<li className="text-justify"> An air carrier issuing a ticket for carriage over the lines of another air carrier does so only as its agent.</li>

<li className="text-justify"> Any exclusion or limitation of liability of carrier shall apply to and be for the benefit of agents, servants and representatives of carrier and any person whose aircraft is used by carrier for carriage and its agents, servants and representatives.</li>

<li className="text-justify"> Checked baggage will be delivered to bearer of the baggage check. In case of damage to baggage moving in international transportation complaint must be made in writing to carrier forthwith after discovery of damage and, at the latest, within seven days from receipt; in case of delay, complaint must be made within 21 days from the date the baggage was delivered. See tariffs or conditions of carriage regarding non international transportation.</li>

<li className="text-justify"> This ticket is good for carriage for one year from date of issue except asotherwise provided in this ticket, in carrier’s tariffs, conditions of carriage, or related regulations. The fare for carriage hereunder is subject to change prior to commencement of carriage. Carrier may refuse transportation if the applicable fare has not been paid.</li>

<li className="text-justify"> Carrier undertakes to use its best efforts to carry the passenger and baggage with reasonable dispatch. Times shown in the Charter Agreement, this ticket or elsewhere are not guaranteed and form no part of this contract. Carrier may without notice substitute alternate carriers or aircraft, and may alter or omit stopping places shown on the ticket in case of necessity. Schedules are subject to change without notice. Carrier assumes no responsibility for making connections.</li>

<li className="text-justify"> Passengers shall comply with Government travel requirements, present exit, entry and other required documents and arrive at airport by time fixed by carrier or, if no time is fixed, early enough to complete departure procedures.</li>

<li className="text-justify"> No agent, servant or representative of carrier has authority to alter, modify or waive any provision of this contract.</li>
</ol>

<div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>


<h2>VERSION FRANCAISE</h2>

<p className="text-justify">Si le voyage du passager implique une destination finale ou un arrêt dans un pays autre que le pays de départ, la Convention de Varsovie ou la Convention de Montréal peut être applicable et ces Conventions régissent et peuvent limiter la responsabilité des transporteurs en cas de décès ou de lésions corporelles et en cas de perte. ou des dommages aux bagages. Voir également les avis intitulés «Conseils aux passagers internationaux sur la limitation de responsabilité» et «Avis de limitation de responsabilité des bagages»</p>

<h3>CONDITIONS DU CONTRAT</h3>
<ol>
<li className="text-justify"> Tel qu’utilisé dans le présent contrat: «billet» désigne ce billet de passager et l’enregistrement des bagages ou cet itinéraire / reçu le cas échéant, dans le cas d’un billet électronique, dont ces conditions et les avis font partie; «Transport» équivaut à «transport»; «Transporteur» désigne tous les transporteurs aériens qui transportent ou s’engagent à transporter le passager ou ses bagages en vertu des présentes ou effectuent tout autre service accessoire à ce transport aérien; «Billet électronique» désigne l’itinéraire / reçu émis par ou au nom du transporteur, les coupons électroniques et, le cas échéant, un document d’embarquement; «Convention de Varsovie» signifie la Convention pour l’unification de certaines règles relatives au transport aérien international signée à Varsovie, le 12 octobre 1929, ou cette Convention telle qu’amendée à La Haye, le 28 septembre 1955, selon le cas.</li>

<li className="text-justify"> Le transport en vertu des présentes est soumis aux règles et limitations de responsabilité établies par la Convention de Varsovie ou la Convention de Montréal, à moins que ce transport ne soit pas un «Transport international» tel que défini par cette Convention.</li>

<li className="text-justify"> Dans la mesure où ils ne sont pas en conflit avec le transport susmentionné et les autres services fournis par chaque transporteur sont soumis: (i) aux dispositions contenues dans le billet; (ii) les tarifs applicables; (iii) les conditions de transport du transporteur et la réglementation connexe qui en font partie (et sont disponibles sur demande auprès des bureaux du transporteur), sauf pour le transport entre un lieu aux États-Unis ou au Canada et tout lieu en dehors duquel des tarifs sont en vigueur dans ces pays s’appliquent.</li>

<li className="text-justify"> Le nom du transporteur peut être abrégé sur le billet, le nom complet et son abréviation étant indiqués dans les tarifs, conditions de transport, réglementations ou horaires du transporteur; l’adresse du transporteur doit être à l’aéroport de départ indiquée en face de la première abréviation du nom du transporteur sur le billet; les points d’arrêt convenus sont ceux indiqués sur ce billet, ou itinéraire / reçu ou tout supplément, ou comme indiqué dans l’horaire du transporteur comme points d’arrêt programmés sur le trajet du passager; le transport devant être effectué par plusieurs transporteurs successifs est considéré comme une seule opération.</li>

<li className="text-justify"> Un transporteur aérien qui délivre un billet pour le transport sur les lignes d’un autre transporteur aérien ne le fait qu’en qualité d’agent.</li>

<li className="text-justify"> Toute exclusion ou limitation de responsabilité du transporteur s’applique aux agents, préposés et représentants du transporteur et à toute personne dont l’aéronef est utilisé par le transporteur pour le transport et à ses agents, préposés et représentants et s’y applique.</li>

<li className="text-justify"> Les bagages enregistrés seront livrés au porteur de l’enregistrement des bagages. En cas de dommages aux bagages transportés dans le transport international, la réclamation doit être adressée par écrit au transporteur immédiatement après la découverte des dommages et, au plus tard, dans les sept jours suivant la réception; en cas de retard, la réclamation doit être effectuée dans un délai de 21 jours à compter de la date de livraison des bagages. Voir tarifs ou conditions de transport concernant le transport non international.</li>

<li className="text-justify"> Ce billet est valable pour le transport pendant un an à compter de la date d’émission, sauf indication contraire dans ce billet, dans les tarifs du transporteur, les conditions de transport ou les réglementations connexes. Le prix du transport ci-dessous est susceptible de changer avant le début du transport. Le transporteur peut refuser le transport si le tarif applicable n’a pas été payé.</li>

<li className="text-justify"> Le transporteur s’engage à faire ses meilleurs efforts pour transporter le passager et les bagages avec une diligence raisonnable. Les horaires indiqués dans le contrat d’affrètement, ce billet ou ailleurs ne sont pas garantis et ne font pas partie de ce contrat. Le transporteur peut, sans préavis, remplacer d’autres transporteurs ou avions, et peut modifier ou omettre les arrêts indiqués sur le billet en cas de nécessité. Les horaires sont sujets à changement sans préavis. Le transporteur n’assume aucune responsabilité pour l’établissement des connexions.</li>

<li className="text-justify"> Les passagers doivent se conformer aux exigences du gouvernement en matière de voyage, présenter les documents de sortie, d’entrée et autres documents requis et arriver à l’aéroport à l’heure fixée par le transporteur ou, si aucune heure n’est fixée, suffisamment tôt pour achever les procédures de départ.</li>

<li className="text-justify"> Aucun agent, préposé ou représentant du transporteur n’a le pouvoir d’altérer, de modifier ou de renoncer à toute disposition du présent contrat.</li>
</ol>











        
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Location
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Aéronef
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Contrat
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Manifestation d'intéré pour la mise à disposition d’appareils
        </h3>
        <form className="nc-SingleCommentForm mt-5">

                <label className="block">
                  <Label>Votre Nom & Prénom</Label>
                  <Input
                    placeholder="Nom Prénom"
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Société ou organisme</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Téléphone</Label>
                  <Input
                    placeholder=""
                    type="text"
                    className="mt-1"
                  />
                </label>

                <label className="block">
                  <Label>Votre adresse email</Label>

                  <Input
                    type="email"
                    placeholder="adresse@email.com"
                    className="mt-1"
                  />
                </label>
                <label className="block">
                  <Label>Votre message</Label>
          <Textarea />
          </label>
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Envoyez</ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
    <Helmet>
      <title>Tassili Airlines || Charte de protection des données personnelles</title>
    </Helmet>
    {renderHeader()}

    <div className="nc-SingleContent container space-y-10">
      {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*renderAuthor()*/}
        {/*renderCommentLists()*/}
      </div>

<br/>
{/*
      <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Autres services</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>

*/}

    </div>
  );
};

export default BlogSingle;
